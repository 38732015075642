import buyerAccount from './modules/buyer-account'
import auth from './modules/auth'
import aja from './modules/aja'
import main from './modules/main'
import catalog from './modules/catalog'
import brand from './modules/brand'
import shoppingCenter from './modules/shopping-center'
import collection from './modules/collection'
import cart from './modules/cart'
import favorite from './modules/favorite'
import jewelry from './modules/jewelry'
import watches from './modules/watches'
import souvenir from './modules/souvenir'
import product from './modules/product'
import offer from './modules/offer'
import user from './modules/user'
import services from './modules/services'
import ui from './modules/ui'
import siteMode from './modules/site-mode'
// import preOrder from './modules/pre-order'

export default {
  modules: {
    auth,
    aja,
    catalog,
    main,
    brand,
    shoppingCenter,
    collection,
    cart,
    favorite,
    buyerAccount,
    jewelry,
    watches,
    souvenir,
    product,
    offer,
    user,
    services,
    ui,
    siteMode,
    // preOrder,
    namespaced: true
  }
}
