export default async function ({ store, redirect, localePath, $cookies, route }) {
  try {
    await store.dispatch('auth/getCurrentUser')
    const currentUser = store.state.auth.currentUser
    const isBuyer = currentUser.role_id === 4
    if (!currentUser) {
      redirect(localePath('/auth/login'))
      return
    }
    if (!route.fullPath.includes('pre-order')) {
      if (currentUser && !isBuyer) {
        const lang = await $cookies.get('lang')

        redirect(
          `https://admin.goldcenter.am?token=${$cookies.get(
            'accessToken'
          )}&lang=${lang}`
        )
        return
      }
    }
  } catch {
    redirect(localePath('/auth/login'))
  }
}
